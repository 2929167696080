import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WindowRefService } from '../../modules/share/services/window-ref.service';
import { filter, from, map, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface Shift4 {
  // eslint-disable-next-line @typescript-eslint/no-misused-new
  new (key: string): Shift4;
  createToken<T>(components: T): Promise<string>;
  createComponentGroup(): {
    automount(formId: string): Shift4;
  };
}

@Injectable({ providedIn: 'root' })
export class Shift4PaymentService {
  shift4: Shift4 | null = null;
  shift4Components: any;
  document = inject(DOCUMENT);
  windowRef = inject(WindowRefService);

  init(paymentId: string) {
    return new Observable(observe => {
      try {
        if (this.shift4) {
          observe.next({ event: 'initialized', errors: null });
        } else {
          this.shift4 = new this.windowRef.nativeWindow.Shift4(environment.shift4Key as string);
          this.shift4Components = this.shift4.createComponentGroup().automount(paymentId);
        }
      } catch (error: any) {
        observe.error('Invalid paymentCardId or paymentCvvId');
      }
    });
  }

  createToken() {
    if (this.shift4) {
      return from(this.shift4?.createToken(this.shift4Components)).pipe(
        map((token: any) => token.id),
        filter(token => !!token)
      );
    }

    return of('');
  }
}
