import { MembershipLevelOffer, MembershipsLevel } from '@aaa/emember/store-price-offers';
import { MzpMembershipLevel } from '@aaa/interface-joinRenew-membership-membershipMzp';
import { ComparePlanItem } from '../../../types/compare-plan';
import { findLevelOfferAmount } from '../utils';

export const compareCharts = (
  levels: MembershipsLevel[],
  levelOffers: MembershipLevelOffer | null
): ComparePlanItem[][] => {
  const basicLevel = levels.find(l => !l.rv && l.level === MzpMembershipLevel.BASIC);
  const plusLevel = levels.find(l => !l.rv && l.level === MzpMembershipLevel.PLUS);
  const plusRvLevel = levels.find(l => l.rv && l.level === MzpMembershipLevel.PLUS_RV);
  const premiumLevel = levels.find(l => !l.rv && l.level === MzpMembershipLevel.PREMIER);
  const premiumRvLevel = levels.find(l => l.rv && l.level === MzpMembershipLevel.PREMIER_RV);

  return [
    [
      {
        title: 'Get RV and Motorcycle coverage',
        description:
          'Add coverage for Motorcycles, Motor homes, Pickup with camper, Travel trailers, and Camping trailers.',
      },
      {
        description: 'Coverage unavailable for Basic membership levels',
      },
      {
        button: true,
        buttonLabel: 'Plus RV',
        buttonId: 'plus_rv',
        buttonParam: plusRvLevel?.level ? { membershiplevel: plusRvLevel.level, rv: true } : {},
      },
      {
        button: true,
        buttonLabel: 'Premier RV',
        buttonId: 'premier_rv',
        buttonParam: premiumRvLevel?.level ? { membershiplevel: premiumRvLevel.level, rv: true } : {},
      },
    ],
    [
      {
        title: 'Add Family Members',
        description:
          "Membership may be extended to anyone living in the primary member's household or to students away at college. Each associate membership provides the same privileges as the primary member and will carry their own card.",
      },
      {
        description: `Basic: ${findLevelOfferAmount(basicLevel, levelOffers, 'associate')} Per Year`,
      },
      {
        description: `Plus: ${findLevelOfferAmount(plusRvLevel, levelOffers, 'associate')} Per Year`,
      },
      {
        description: `Premier: ${findLevelOfferAmount(premiumRvLevel, levelOffers, 'associate')} Per Year`,
      },
    ],
    [
      {
        title: 'Towing Coverage',
        description:
          'When you need us most - get reliable 24/7 roadside assistance, available throughout the USA and Canada.',
      },
      {
        description: basicLevel?.level ? `${basicLevel.towMiles} miles or back to responding station` : '',
      },
      {
        description: plusLevel?.level ? `Up to ${plusLevel.towMiles} miles in any direction` : '',
      },
      {
        description: premiumLevel?.level
          ? `Up to ${premiumLevel?.towMiles} miles on one service call per household, per membership year; up to 100 miles on other calls`
          : '',
      },
    ],
    [
      {
        title: 'Number of Service Calls',
        description:
          'Need a tow or other service call? The primary member and each associate on the account can receive up to the alloted number of service calls per membership year.',
      },
      {
        description: '4 calls',
      },
      {
        description: `4 calls`,
      },
      {
        description: `4 calls`,
      },
    ],
    [
      {
        title: 'Vehicle Lockout / Locksmith Service',
        description:
          'If key is locked inside vehicle, lost or broken; locksmith service is covered up to the limit of benefit.',
      },
      {
        description: 'Up to $50 for parts and labor',
      },
      {
        description: `Up to $100 for parts and labor`,
      },
      {
        description: `Up to $100 for parts and labor`,
      },
    ],
    [
      {
        title: 'Emergency Fuel Delivery',
        description:
          'When a vehicle runs out of fuel, a service technician will bring enough fuel to reach the nearest service station, or the vehicle will be towed to a facility where fuel is available. AAA cannot guarantee specific brands, octane ratings, or the availability of diesel fuel. If the vehicle will not start after fueling, towing may be provided.',
      },
      {
        description: 'Member pays prevailing price for fuel',
      },
      {
        description: 'Member receives free fuel and free delivery.',
      },
      {
        description: 'Member receives free fuel and free delivery.',
      },
    ],
    [
      {
        title: 'Extrication',
        description:
          'When a vehicle becomes stuck and can be safely reached from a normally traveled roadway, service will be provided using standard automobile servicing equipment. If the vehicle cannot be safely driven after winching, it may be towed. Plus RV coverage may be required, see handbook for restrictions.',
      },
      {
        description: 'Provides one (1) Rescue Vehicle & Technician',
      },
      {
        description: 'Provides 2nd Rescue Vehicle & 2nd Technician up to one (1) hour',
      },
      {
        description: 'Provides 2nd Rescue Vehicle & 2nd Technician up to one (1) hour',
      },
    ],
    [
      {
        title: 'RV Roadside Assistance Coverage',
        description:
          'An extension of AAA Plus. RV is considered to be a motor home, truck with camper, large dual wheel pickup, travel trailer or camping trailer. Restrictions may apply*.',
      },
      {
        description: '',
      },
      {
        description: 'Available for Plus RV',
      },
      {
        description: 'Available for Premier RV',
      },
    ],
    [
      {
        title: 'Auto Theft Reward',
        description:
          "As a deterrent to theft and vandalism, a member's car is protected by a warning stating that AAA offers a $$$ reward for information leading directly to the arrest and conviction of anyone stealing a member's car or instance involving a hit and run. Restrictions apply.",
      },
      {
        description: 'Reward of $1,000',
      },
      {
        description: 'Reward of $2,000',
      },
      {
        description: 'Reward of $2,000',
      },
    ],
    [
      {
        title: 'Travel Accident Insurance',
        description:
          'When you purchase scheduled airline, train, bus or cruise tickets at your local AAA Travel Agency, you are automatically covered with up to $100,000/$400,000 in travel accident insurance at no cost.',
      },
      {
        description: 'Available for purchase',
      },
      {
        description: '$300,000 when booked with AAA',
      },
      {
        description: '$300,000 when booked with AAA',
      },
    ],
    [
      {
        title: 'Passport Photos',
        description:
          'Get your passport photos the easy way. Visit your local AAA service center where we can take your photos and print them right away.',
      },
      {
        description: 'Available for purchase',
      },
      {
        description: '2 FREE Photo Sets per year',
      },
      {
        description: '2 FREE Photo Sets per year',
      },
    ],
    [
      {
        title: 'Hertz Gold® Membership Enrollment',
        description:
          'Get free enrollment with AAA membership and receive enrollment bonus points after completing first paid Gold rental. Use your points for free rentals, experiences and rewards.',
      },
      {
        description:
          'Free Hertz Gold membership with enrollment. After first Gold rental, receive enough Hertz Gold Plus Rewards Bonus Points for ONE FREE rental day',
      },
      {
        description:
          'Free Hertz Gold membership with enrollment. After first Gold rental, receive enough Hertz Gold Plus Rewards Bonus Points for ONE FREE rental day',
      },
      {
        description:
          'Free Hertz Gold membership with enrollment. After first Gold rental, receive enough Hertz Gold Plus Rewards Bonus Points for ONE FREE rental day',
      },
    ],
    [
      {
        title: 'Battery Service',
        description:
          'Car battery on-the-spot testing and jumpstart. Battery sales and free installation available in select area.',
      },
      {
        description: 'Included',
      },
      {
        description: 'Included',
      },
      {
        description: 'Included',
      },
    ],
    [
      {
        title: 'Flat Tire Change - No charge to install inflated/serviceable spare',
        description: 'Included',
      },
      {
        description: 'Included',
      },
      {
        description: 'Included',
      },
      {
        description: 'Included',
      },
    ],
    [
      {
        title: 'Bicycle Coverage',
        description: '',
      },
      {
        description: 'Included',
      },
      {
        description: 'Included',
      },
      {
        description: 'Included',
      },
    ],
    [
      {
        title: 'Trip Interruption Protection',
        description: '',
      },
      {
        description: 'Protection of up to $200',
      },
      {
        description: 'Protection of up to $700',
      },
      {
        description: 'Coverage up to $1,500**',
      },
    ],
    [
      {
        title: 'Home Lockout Service',
        description: 'Up to $100 reimbursement per household per year',
      },
      {
        description: '',
      },
      {
        description: '',
      },
      {
        description: 'Included',
      },
    ],
    [
      {
        title: '24-Hour Travel Concierge Services**',
        description:
          'Exclusive toll-free number for personal travel services, event tickets, restaurant reservations, tee times and more',
      },
      {
        description: '',
      },
      {
        description: '',
      },
      {
        description: 'Included',
      },
    ],
  ];
};
