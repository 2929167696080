import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { JoinActions } from './join.actions';
import { exhaustMap, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { FormGroupValue } from '../../modules/share/form.utils';
import { getPayment } from '@aaa/emember/store-payment';
import { JoinForm, JoinPayParams } from './join.models';
import { PaymentForm } from '@aaa/emember/share/payment-form';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { DataLayerService } from '../../modules/share/services/data-layer.service';
import {
  MembershipCampanaGetCostsJoinEvent,
  MembershipCampanaGetCostsJoinResponse,
  MembershipCampanaLevel,
  MembershipCampanaMethod,
  MembershipCampanaOperationExecuteEvent,
} from '@aaa/interface-joinRenew-membership-membershipCampana';
import { getClearCacheSettings } from '../utils/get-cache-settings';
import { ValidateSucceededResponseObject } from '@aaa/emember/store-types';
import { CampanaType } from '../campana.type';
import { getCampanaMembershipType } from '../campana-utils';
import { MembershipOfferItem } from '../price-offers/helpers/types';
import { PriceOffersActions } from '@aaa/emember/store-price-offers';
import { Operation, OperationExecutePayload } from '@aaa/interface-joinRenew-joinRenewLib';
import { Cybersource } from '../cybersource.type';
import { AnalyticsPurchaseEvent } from '../../../types/analytics-purchase-event';
import { getTransactionId } from '../utils/get-transaction-id';
import { AppAnalyticsEvents } from '../../../types/analytics-events';
import {
  PaymentShift4Method,
  PaymentShift4OperationExecuteEventPayload,
} from '@aaa/interface-joinRenew-payment-paymentShift4';
import { RequestError, RequestErrorType } from '../generic-errors';
import { encryptPassword } from '../utils/encrypt-password';

@Injectable({ providedIn: 'root' })
export class JoinCampanaSystemEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Northway]));
  executeService = inject(ExecuteService);
  dataLayer = inject(DataLayerService);

  updateSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.recostValidateSucceeded),
      mergeMap(
        ({ response }: ValidateSucceededResponseObject<MembershipCampanaGetCostsJoinResponse['sessionResponse']>) => {
          const totalCost = response.data.dues.data.total;
          const componentOffers: MembershipOfferItem[] = [];
          const membershipType = getCampanaMembershipType(response.data.level.data.serviceLevels[0].level);

          response.data.level.data.serviceLevels.map(serviceLevel => {
            componentOffers.push({
              offering: serviceLevel.personId === '00' ? 'primary' : 'associate',
              code: getCampanaMembershipType(serviceLevel.level),
              description: serviceLevel.level,
              amount: serviceLevel.balance,
              selectedByDefault: true,
              conditions: [],
            });
          });

          if (response.data.dues.data.miscCharges > 0) {
            componentOffers.push({
              offering: 'optionalPrimary',
              code: 'waiveEnrollFee',
              description: 'One-Time Enrollment Fee',
              amount: response.data.dues.data.miscCharges,
              selectedByDefault: true,
              conditions: [],
            });
          }

          // miscCharges
          return [
            JoinActions.setTotalCost({ totalCost }),
            PriceOffersActions.updateAndInsert({ membershipType, componentOffers }),
          ];
        }
      )
    )
  );

  recostValidateJoin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        JoinActions.recostValidate,
        JoinActions.updateRecostValidate,
        JoinActions.validatePromoCode,
        JoinActions.retryRecostValidate
      ),
      exhaustMap(({ formValues }) =>
        of(formValues).pipe(
          switchMap(action => of(action).pipe(withLatestFrom(this.store.select(getPayment)))),
          switchMap(([formValues, payment]) =>
            this.recostValidation(formValues, payment).pipe(
              map(res => JoinActions.recostValidateSucceeded(res)),
              catchError(error => of(JoinActions.recostValidateFailed({ error })))
            )
          )
        )
      )
    )
  );

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.pay),
      exhaustMap(({ params }) =>
        this.pay(params).pipe(
          map(({ membership, payment }) => JoinActions.paySucceeded({ membership, payment })),
          catchError(error => of(JoinActions.payFailed({ error })))
        )
      )
    )
  );

  pay(params: JoinPayParams) {
    const { totalCost, executionData, selectedLevel, page, payment } = params;

    const paymentEvent: PaymentShift4OperationExecuteEventPayload = {
      method: PaymentShift4Method.OPERATION_EXECUTE,
      operation: Operation.JOIN,
      executionData: {
        tokenId: payment.token,
        paymentAmount: totalCost,
        // holder: 'VISA',
        // expiry: '02/33',
      },
    };

    const membershipEvent: MembershipCampanaOperationExecuteEvent = {
      executionData: executionData,
      method: MembershipCampanaMethod.OPERATION_EXECUTE,
      operation: Operation.JOIN,
    };

    const payload: OperationExecutePayload = {
      operation: Operation.JOIN,
      membershipEvent: membershipEvent,
      paymentEvent: paymentEvent,
    };

    return this.executeService
      .execute<CampanaType.JoinExecuteResponseObject, Cybersource.ExecutePaymentResponseObject>(payload)
      .pipe(
        map(({ paymentObject, validateObject, operationObject }) => {
          console.log('response', paymentObject, validateObject, operationObject);

          if (paymentObject?.error) {
            throw new RequestError(RequestErrorType.PaymentTypeError, validateObject);
          }

          if (validateObject?.error) {
            throw new RequestError(RequestErrorType.MembershipError, validateObject);
          }

          if (operationObject?.error) {
            throw new RequestError(RequestErrorType.JoinFormError, operationObject);
          }

          const analyticsEventParams: AnalyticsPurchaseEvent['eventParams'] = {
            currency: 'USD',
            transaction_id: getTransactionId(paymentObject),
            value: totalCost,
            items: [
              {
                quantity: 1,
                item_id: 'primary',
                price: totalCost,
                item_name: page === 'join' ? AppAnalyticsEvents.JoinNew : AppAnalyticsEvents.GiftNew,
              },
            ],
            context: 'ava-store ' + (page === 'join' ? AppAnalyticsEvents.JoinNew : AppAnalyticsEvents.GiftNew),
            membershipLevel: selectedLevel.level,
          };
          this.dataLayer.purchaseEvent(analyticsEventParams);

          // const membershipError =
          //   validateObject?.meta.isError || validateObject?.response.response?.attributes?.responseCode !== '000';
          //
          // if (membershipError) {
          //   throw new RequestError(RequestErrorType.MembershipError, validateObject);
          // }

          // const operationError = !!operationObject?.meta?.isError;
          // if (operationError) {
          //   checkOperationErrorsConnectSuiteSystem(operationObject.error, operationObject);
          // }

          return { membership: validateObject, payment: paymentObject };
        })
      );
  }

  recostValidation(
    formValues: FormGroupValue<JoinForm>,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> }
  ) {
    const payload: MembershipCampanaGetCostsJoinEvent = {
      method: MembershipCampanaMethod.GET_COSTS_JOIN,
      cacheSettings: getClearCacheSettings(),
      primary: {
        firstName: formValues.memberInfo?.account?.firstName || 'FakeFirstName',
        lastName: formValues.memberInfo?.account?.lastName || 'FakeLastName',
        birthDate: formValues.memberInfo?.account?.birthday || '2000-09-24',
        email: formValues.memberInfo?.account?.email || '',
        gender: '',
      },
      associates: (formValues.memberInfo?.membershipAssociates || []).map(associate => {
        return {
          birthDate: associate.birthday || '2000-09-24',
          firstName: associate.firstName || 'FakeFirstName',
          lastName: associate.lastName || 'FakeLastName',
          gender: '',
          clubJoinDate: '',
          cueDate: '',
          previousMemberNumber: '',
          salutation: '',
        };
      }),
      address: {
        street: [
          formValues.memberInfo?.account?.address1 || 'FakeAddress',
          formValues.memberInfo?.account?.address2 || '',
        ].filter(Boolean),
        locality: formValues.memberInfo?.account?.city || 'FakeLocality',
        region: formValues.memberInfo?.account?.state || '',
        postCode: formValues.memberInfo?.account?.zipcode || '',
      },
      promotionCode: '',
      autoRenew: false,
      level: formValues.memberInfo?.membership?.membershipLevel
        ? (MembershipLevelMapping[
            formValues.memberInfo?.membership?.membershipLevel as keyof typeof MembershipLevelMapping
          ] as MembershipCampanaLevel)
        : MembershipCampanaLevel.Basic,
      loginCredentials: {
        encryptedPassword: encryptPassword(formValues.memberInfo?.account?.password),
      },
    };

    return this.executeService.membershipQuery<CampanaType.JoinRecostValidationResponseObject>(payload).pipe(
      map(({ response }) => {
        // some error
        return { response: response.sessionResponse, executionData: response.executionData };
      })
    );
  }
}

const MembershipLevelMapping = {
  BASIC: MembershipCampanaLevel.Basic,
  PLUS: MembershipCampanaLevel.Plus,
  'PLUS-RV': MembershipCampanaLevel.PlusRV,
  PREMIER: MembershipCampanaLevel.Premier,
};
