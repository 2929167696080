import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { ROUTER_FEATURE_KEY } from './router/router.selectors';
import { NavigationActionTiming, provideRouterStore, routerReducer, RouterState } from '@ngrx/router-store';
import { SESSION_FEATURE_KEY, sessionReducer, SessionEffects } from '@aaa/emember/store-session';
import {
  PRICE_PREVIEW_FEATURE_KEY,
  pricePreviewReducer,
  PricePreviewEffects,
  PricePreviewHoosierEffects,
  PricePreviewMidstatesEffects,
  PricePreviewShelbyEffects,
  PricePreviewNorthamptonEffects,
} from '@aaa/emember/store-price-preview';
import {
  JOIN_FEATURE_KEY,
  joinReducer,
  JoinConnectSuiteEffects,
  JoinMzpSystemEffects,
  JoinEffects,
  JoinMSystemEffects,
} from '@aaa/emember/store-join';
import { PAYMENT_FEATURE_KEY } from '@aaa/emember/store-payment';
import { paymentReducer } from './payment/payment.reducer';
import { SUMMARY_FEATURE_KEY, summaryReducer } from './summary';
import { STATE_FEATURE_KEY } from './states/states.selectors';
import { statesReducer } from './states/states.reducer';
import {
  QUICK_RENEW_FORM_FEATURE_KEY,
  QuickRenewEffects,
  QuickRenewConnectSuitSystemEffects,
  QuickRenewMzpSystemEffects,
  QuickRenewMSystemEffects,
  quickRenewReducer,
} from '@aaa/emember/store-quick-renew';
import { metaReducers } from './meta.reducers';
import { CustomSerializer } from './router/custom-route-serializer';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { PaymentEffects } from './payment/payment.effects';
import { NotificationModule } from '../modules/share/notification/notification.module';
import { validatorsReducer, VALIDATORS_FEATURE_KEY, ValidatorsEffects } from '@aaa/emember/store-validators';
import {
  ACCOUNT_FEATURE_KEY,
  accountReducer,
  AccountEffects,
  AccountConnectSuiteSystemEffects,
  AccountMSystemEffects,
  AccountMzpSystemEffects,
} from '@aaa/emember/store-account';
import {
  membershipAssociateAddReducer,
  MEMBERSHIP_ASSOCIATE_ADD_FEATURE_KEY,
  MembershipAssociateAddEffects,
  MembershipAssociateAddConnectSuitSystemEffects,
  MembershipAssociateAddMSystemEffects,
  MembershipAssociateAddMzpSystemEffects,
} from '@aaa/emember/store-membership-associate-add';
import {
  membershipRenewReducer,
  MembershipRenewEffects,
  MembershipRenewConnectSuiteSystemEffects,
  MEMBERSHIP_RENEW_FEATURE_KEY,
  MembershipRenewMSystemEffects,
  MembershipRenewMzpSystemEffects,
} from '@aaa/emember/store-membership-renew';
import {
  MembershipUpgradePlanEffects,
  membershipUpgradePlanReducer,
  MEMBERSHIP_UPGRADE_PLAN_FEATURE_KEY,
  MembershipUpgradePlanConnectSuitSystemEffects,
  MembershipUpgradePlanMSystemEffects,
  MembershipUpgradePlanMzpSystemEffects,
} from '@aaa/emember/store-membership-upgrade-plan';
import {
  MembershipAutoRenewConnectSuitSystemEffects,
  MEMBERSHIP_AUTO_RENEW_FEATURE_KEY,
  membershipAutoRenewReducer,
  MembershipAutoRenewEffects,
  MembershipAutoRenewMEffects,
  MembershipAutoRenewMzpSystemEffects,
} from '@aaa/emember/store-membership-auto-renew';
import { FEATURE_FLAGS_KEY, FeatureFlagsEffects, featureFlagsReducer } from './feature-flags';
import {
  membershipReducer,
  MEMBERSHIP_FEATURE_KEY,
  MembershipConnectSuiteSystemEffects,
  MembershipMzpSystemEffects,
  MembershipMSystemEffects,
  MembershipEffects,
} from './membership';
import {
  MEMBERSHIP_UPDATE_FEATURE_KEY,
  MembershipUpdateEffects,
  membershipUpdateReducer,
  MembershipUpdateMSystemEffects,
} from './membership-update';
import {
  PRICE_OFFERS_FEATURE_KEY,
  PriceOffersEffects,
  PriceOffersConnectSuiteEffects,
  PriceOffersMSystemEffects,
  priceOffersReducer,
  PriceOffersMzpSystemEffects,
} from './price-offers';
import { MEMBERSHIP_OFFERS_FEATURE_KEY, membershipOffersReducer } from './membership-offers';
import { AnalyticsEffects } from './analytics';
import {
  MOBILE_RENEW_FEATURE_KEY,
  MobileRenewConnectSuiteSystemEffects,
  MobileRenewEffects,
  mobileRenewReducer,
} from './mobile-renew';
import { PriceOffersCampanaSystemEffects } from './price-offers/price-offers-campana-system.effects';
import { JoinCampanaSystemEffects } from './join/join-campana-system.effects';

@NgModule({
  imports: [NotificationModule],
  providers: [
    provideStore(
      {
        [ROUTER_FEATURE_KEY]: routerReducer,
        [MEMBERSHIP_FEATURE_KEY]: membershipReducer,
        [SESSION_FEATURE_KEY]: sessionReducer,
        [PRICE_PREVIEW_FEATURE_KEY]: pricePreviewReducer,
        [JOIN_FEATURE_KEY]: joinReducer,
        [PAYMENT_FEATURE_KEY]: paymentReducer,
        [SUMMARY_FEATURE_KEY]: summaryReducer,
        [STATE_FEATURE_KEY]: statesReducer,
        [VALIDATORS_FEATURE_KEY]: validatorsReducer,
        [QUICK_RENEW_FORM_FEATURE_KEY]: quickRenewReducer,
        [ACCOUNT_FEATURE_KEY]: accountReducer,
        [MEMBERSHIP_ASSOCIATE_ADD_FEATURE_KEY]: membershipAssociateAddReducer,
        [MEMBERSHIP_RENEW_FEATURE_KEY]: membershipRenewReducer,
        [MEMBERSHIP_UPGRADE_PLAN_FEATURE_KEY]: membershipUpgradePlanReducer,
        [MEMBERSHIP_AUTO_RENEW_FEATURE_KEY]: membershipAutoRenewReducer,
        [MEMBERSHIP_OFFERS_FEATURE_KEY]: membershipOffersReducer,
        [MEMBERSHIP_UPDATE_FEATURE_KEY]: membershipUpdateReducer,
        [FEATURE_FLAGS_KEY]: featureFlagsReducer,
        [PRICE_OFFERS_FEATURE_KEY]: priceOffersReducer,
        [MOBILE_RENEW_FEATURE_KEY]: mobileRenewReducer,
      },
      { metaReducers }
    ),
    provideRouterStore({
      serializer: CustomSerializer,
      navigationActionTiming: NavigationActionTiming.PreActivation,
      routerState: RouterState.Minimal,
    }),
    provideStoreDevtools({ maxAge: 25, logOnly: environment.production }),
    provideEffects([
      MembershipEffects,
      MembershipConnectSuiteSystemEffects,
      MembershipMzpSystemEffects,
      MembershipMSystemEffects,
      SessionEffects,
      PricePreviewEffects,
      PricePreviewHoosierEffects,
      PricePreviewMidstatesEffects,
      PricePreviewShelbyEffects,
      PricePreviewNorthamptonEffects,
      PriceOffersEffects,
      PriceOffersMSystemEffects,
      PriceOffersConnectSuiteEffects,
      PriceOffersMzpSystemEffects,
      PriceOffersCampanaSystemEffects,
      JoinConnectSuiteEffects,
      JoinMzpSystemEffects,
      JoinMSystemEffects,
      JoinCampanaSystemEffects,
      JoinEffects,
      PaymentEffects,
      ValidatorsEffects,
      QuickRenewEffects,
      QuickRenewConnectSuitSystemEffects,
      QuickRenewMzpSystemEffects,
      QuickRenewMSystemEffects,
      AccountEffects,
      AccountConnectSuiteSystemEffects,
      AccountMSystemEffects,
      AccountMzpSystemEffects,
      MembershipAssociateAddEffects,
      MembershipAssociateAddConnectSuitSystemEffects,
      MembershipAssociateAddMSystemEffects,
      MembershipAssociateAddMzpSystemEffects,
      MembershipRenewEffects,
      MembershipRenewMSystemEffects,
      MembershipRenewConnectSuiteSystemEffects,
      MembershipRenewMzpSystemEffects,
      MembershipUpgradePlanEffects,
      MembershipUpgradePlanConnectSuitSystemEffects,
      MembershipUpgradePlanMzpSystemEffects,
      MembershipUpgradePlanMSystemEffects,
      MembershipAutoRenewEffects,
      MembershipAutoRenewConnectSuitSystemEffects,
      MembershipAutoRenewMEffects,
      MembershipAutoRenewMzpSystemEffects,
      MembershipUpdateEffects,
      MembershipUpdateMSystemEffects,
      FeatureFlagsEffects,
      AnalyticsEffects,
      MobileRenewConnectSuiteSystemEffects,
      MobileRenewEffects,
    ]),
  ],
})
export class AppStoreModule {}
