
export function interfaceJoinRenewMembershipMembershipConnectSuite(): string {
  return "interface-join-renew-membership-membership-connect-suite"
}

import { 
  EventPayload, 
  Operation, 
  OperationEventResponse 
} from "@aaa/interface-joinRenew-joinRenewLib"

/**
 * Membership Subsystem Events (Connectsuite)
 */

export enum MembershipConnectSuiteMethod {
  IS_ALIVE = "IS_ALIVE",
  PRICE_PREVIEWS = "PRICE_PREVIEWS",

  MEMBER_LOOKUP = "MEMBER_LOOKUP",

  RECOST_VALIDATE_JOIN = "RECOST_VALIDATE_JOIN",
  RECOST_VALIDATE_RENEW = "RECOST_VALIDATE_RENEW",

  RECOST_VALIDATE_CHANGE_AR = "RECOST_VALIDATE_CHANGE_AR",
  RECOST_VALIDATE_CHANGE_CC = "RECOST_VALIDATE_CHANGE_CC",
  RECOST_VALIDATE_CHANGE_LEVEL = "RECOST_VALIDATE_CHANGE_LEVEL",
  RECOST_VALIDATE_CHANGE_ASSOCIATES = "RECOST_VALIDATE_CHANGE_ASSOCIATES",

  RECOST_VALIDATE_CHANGE_PHONE = "RECOST_VALIDATE_CHANGE_PHONE",
  RECOST_VALIDATE_CHANGE_ADDRESS = "RECOST_VALIDATE_CHANGE_ADDRESS",
  RECOST_VALIDATE_CHANGE_EMAIL = "RECOST_VALIDATE_CHANGE_EMAIL",
  RECOST_VALIDATE_CHANGE_CARD_FORMAT = "RECOST_VALIDATE_CHANGE_CARD_FORMAT",

  OPERATION_EXECUTE = "OPERATION_EXECUTE"
}

export interface MembershipConnectSuiteEventPayload extends EventPayload {
  method: MembershipConnectSuiteMethod;
  responseType?: unknown;
}

export interface MembershipConnectSuiteIsAliveEventPayload extends MembershipConnectSuiteEventPayload {
  method: MembershipConnectSuiteMethod.IS_ALIVE;

  bypassProxy?: boolean;

  function?: "stress" | "isAlive" | "ipify";
  stressAmount?: number;

  responseType?: MembershipConnectSuiteIsAliveResponse;
}

export interface MembershipConnectSuiteIsAliveResponse {
  isAlive: boolean;
  extraInfo?: any;
}

export interface MembershipConnectSuiteMemberLookupEventPayload extends MembershipConnectSuiteEventPayload {
  method: MembershipConnectSuiteMethod.MEMBER_LOOKUP;
  memberNumber: string;

  responseType?: MembershipConnectSuiteMemberLookupResponse;
}

export interface MembershipConnectSuiteMemberLookupResponse {
  memberInfo: any;
}

export interface MembershipConnectSuitePricePreviewsEventPayload extends MembershipConnectSuiteEventPayload {
  method: MembershipConnectSuiteMethod.PRICE_PREVIEWS;
  postalCode: string; // hoosier: '08043'
  promoCode?: string;
  couponCode?: string; // T-Mobile, if couponCode is sent, promoCode is ignored

  responseType?: MembershipConnectSuitePricePreviewsResponse;
}

export interface MembershipConnectSuitePricePreviewsResponse {
  promoNoEnrollPrices: ConnectSuitePrices[];
  noPromoNoEnrollPrices: ConnectSuitePrices[];
  enrollFee: number;
  promoCode?: string;
  promoNoEnrollResponse: any;
}

export enum ConnectSuiteMembershipLevel {
  CLASSIC = "CLASSIC",
  PLUS = "PLUS",
  PREMIER = "PREMIER"
}

export const connectSuiteMembershipCodes: ConnectSuiteMembershipCode[] = [
  {
    label: "Classic",
    level: ConnectSuiteMembershipLevel.CLASSIC,
    rv: false,
    membershipType: "B",
    duesComponentCode: "BS",
  },
  {
    label: "Plus",
    level: ConnectSuiteMembershipLevel.PLUS,
    rv: false,
    membershipType: "P",
    duesComponentCode: "PL",
  },
  {
    label: "Plus RV",
    level: ConnectSuiteMembershipLevel.PLUS,
    rv: true,
    membershipType: "R",
    duesComponentCode: "RP",
  },
  {
    label: "Premier",
    level: ConnectSuiteMembershipLevel.PREMIER,
    rv: false,
    membershipType: "E",
    duesComponentCode: "EP",
  },
]

export interface ConnectSuiteMembershipCode {
  label: string;
  level: ConnectSuiteMembershipLevel;
  rv: boolean;
  membershipType: string;
  duesComponentCode: string;
}

export interface ConnectSuitePrices {
  error?: {
    "responseText": string; // "The coupon code has already been redeemed.",
    "version": string; // "1.3",
    "responseCode": string; // "203",
    "waiveEnrollFee": string; // "false"
  };
  level: ConnectSuiteMembershipLevel;
  rv: boolean;
  prices: { members: number; price: number | undefined; }[];
  primaryFee: number;
  associateFee: number;
}

export interface MembershipConnectSuiteRecostValidateRenewEventPayload extends MembershipConnectSuiteEventPayload {
  method: MembershipConnectSuiteMethod.RECOST_VALIDATE_RENEW;
  memberNumber: string;
  verificationData: {
    lastName: string;
    postalCode: string;
  };
  autoRenew: boolean | null;
  responseType?: MembershipConnectSuiteRecostValidateRenewResponse;
}

export interface MembershipConnectSuiteRecostValidateRenewResponse {
  validationData: any;
  executionData?: string; //pass to execute renew event
}

export interface MembershipConnectSuiteRecostValidateChangeLevelEventPayload extends MembershipConnectSuiteEventPayload {
  method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_LEVEL;
  memberNumber: string;
  newLevel: {
    level: ConnectSuiteMembershipLevel | null;
    rv: boolean | null;
  };
  autoRenew: boolean | null;
  responseType?: MembershipConnectSuiteRecostValidateChangeLevelResponse;
}

export interface MembershipConnectSuiteRecostValidateChangeLevelResponse {
  validationData: any;
  executionData?: string; //pass to execute renew event
}

export interface MembershipConnectSuiteRecostValidateChangeAREventPayload extends MembershipConnectSuiteEventPayload {
  method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_AR;
  memberNumber: string;
  autoRenew: boolean | null;
  responseType?: MembershipConnectSuiteRecostValidateChangeARResponse;
}

export interface MembershipConnectSuiteRecostValidateChangeARResponse {
  validationData: any;
  executionData?: string; //pass to execute renew event
}

export interface MembershipConnectSuiteRecostValidateChangeEmailEventPayload extends MembershipConnectSuiteEventPayload {
  method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_EMAIL;
  memberNumber: string;
  email: string;
  responseType?: MembershipConnectSuiteRecostValidateChangeEmailResponse;
}

export interface MembershipConnectSuiteRecostValidateChangeEmailResponse {
  validationData: any;
  executionData?: string; //pass to execute renew event
}

export interface MembershipConnectSuiteRecostValidateChangePhoneEventPayload extends MembershipConnectSuiteEventPayload {
  method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_PHONE;
  memberNumber: string;

  businessPhone?: string;
  cellPhone?: string;
  homePhone?: string,

  responseType?: MembershipConnectSuiteRecostValidateChangePhoneResponse;
}

export interface MembershipConnectSuiteRecostValidateChangePhoneResponse {
  validationData: any;
  executionData?: string; //pass to execute renew event
}

export interface MembershipConnectSuiteRecostValidateChangeAssociatesEventPayload extends MembershipConnectSuiteEventPayload {
  method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_ASSOCIATES;
  memberNumber: string;
  associates: MembershipPayloadAssociate[];
  associateCount?: number;
  autoRenew: boolean | null;
  promoCode?: string;
  responseType?: MembershipConnectSuiteRecostValidateChangeAssociatesResponse;
}

export interface MembershipConnectSuiteRecostValidateChangeAssociatesResponse {
  validationData: any;
  executionData?: string; //pass to execute renew event
}

export interface MembershipConnectSuiteRecostValidateChangeAddressEventPayload extends MembershipConnectSuiteEventPayload {
  method: MembershipConnectSuiteMethod.RECOST_VALIDATE_CHANGE_ADDRESS;
  memberNumber: string;
  address: {
    address1?: string;
    address2?: string;
    cityName?: string;
    StateProv?: string;
    postalCode: string;
  };
  responseType?: MembershipConnectSuiteRecostValidateChangeAddressResponse;
}

export interface MembershipConnectSuiteRecostValidateChangeAddressResponse {
  validationData: any;
  executionData?: string; //pass to execute renew event
}

export interface MembershipConnectSuiteRecostValidateJoinEventPayload extends MembershipConnectSuiteEventPayload {
  method: MembershipConnectSuiteMethod.RECOST_VALIDATE_JOIN;
  membership: {
    membershipLevel: ConnectSuiteMembershipLevel;
    rv: boolean;
    associateCount: number;
    address: {
      address1?: string;
      address2?: string;
      cityName?: string;
      StateProv?: string;
      postalCode: string;
    };
    promoCode?: string;
    programCode?: string;   // t-mobile/shell, used for tracking
    couponCode?: string;    // T-Mobile/Shell unique codes, if couponCode is sent, promoCode is ignored
    autoRenew: boolean | null;
    cardFormat?: CardFormat | null;
  };

  primary?: {
    firstName: string;
    lastName: string;
    dob: string; // todo - "199000101"
    cellPhone: string; // "1234567890" - todo - phone types?
    email: string;
    nameSuffix: LastNameSuffix; // added 8/24/2022


    // this are copied from operation join response object - membership.primaryMember.attribute
    middleIntial?: string;
    homePhone?: string;
    businessPhone: string;
    sex?: string;
    title?: string;
  };

  donorMembership?: DonorMembership | null

  associates?: MembershipPayloadAssociate[];

  responseType?: MembershipConnectSuiteRecostValidateJoinResponse;
}

export interface DonorMembership {
  address: {
    attributes: {
      country: string;
      address1: string;
      address2: string;
      cityName: string;
      stateProv: string;
      postalCode: string;
    };
  };
  cellPhone?: string;
  homePhone?: string;
  email: string;
  firstName: string;
  lastName: string;
  membershipNumber?: string;
  middleIntial?: string;
  nameSuffix?: string;
  title?: string;
  giftOptions: {
    giftFrom: string; //user text
    giftMessage: string; //user text
    giftTo: string; //user text
    holidayGift: boolean;
    renewalType: "P" | "O"; //perpetual | one-time
    sendMbrCardTo: "P" | "D"; //primary | donor
  };
}

export enum CardFormat {
  DIGITAL = "DIGITAL",
  PLASTIC = "PLASTIC",
  BOTH = "BOTH"
}

export interface MembershipPayloadAssociate {
  firstName: string;
  lastName: string;

  membershipNumber?: string; // required for edit
  removeAssociate?: boolean;

  middleIntial?: string;
  nameSuffix?: LastNameSuffix;

  // not using in UI atm
  dob?: string;
  email?: string;
}

export type LastNameSuffix = "" | "Jr" | "Sr" | "I" | "II" | "III" | "IV" | "V" | "VI" | "VII";

export interface MembershipConnectSuiteRecostValidateJoinResponse {
  validationData: any;
  executionData?: string; //pass to execute join event
}

export interface MembershipConnectSuiteOperationExecuteEventPayload extends MembershipConnectSuiteEventPayload {
  method: MembershipConnectSuiteMethod.OPERATION_EXECUTE;
  operation: Operation;

  executionData: string;
  loginCredentials?: DrupalNewLoginAccountCredentials; // used for new joins, to create new login account

  payment?: any; //NOT SUPPLIED BY UI, added by function during execution

  responseType?: OperationEventResponse;
}

export interface DrupalNewLoginAccountCredentials {
  email?: string; // can be extracted from primary member info
  password: string;
  encryptedPassword: string;
  zip?: string; // can be extracted from primary member info


  // membership number known after successful join
  iso?: string;
  club?: string;
  household?: string;
  associate?: string;
  check_digit?: string;
}
