import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Block } from '../../block/services/block';
import { StateService } from '../../services/state';
import { CustomOption, QuillEditorComponent, QuillModules } from 'ngx-quill';
import { QuillEditorOptions, QuillEditorStyles, QuillEditorService } from './service';
import { RxState } from '@rx-angular/state';
import { BlockState } from '../../block/services/state';
import { FormService } from '../../services/form';

@Component({
  selector: 'ava-quill-form-quill-inline',
  templateUrl: './form-quill-inline.html',
})
export class QuillFormQuillInlineComponent implements OnInit {
  @Input() fieldPath: string[] = [];
  @Input() stateId: string | undefined;
  @Input() rowId: string | undefined;
  @Input() options: QuillEditorOptions | undefined;
  @Input() stylesFunction: ((block: Block, width: number, backgroundUrl: boolean) => QuillEditorStyles) | undefined;
  @ViewChild('quillEditorComponent') quillEditorComponent: QuillEditorComponent | undefined;
  formGroup: FormGroup | undefined;
  blockState: RxState<BlockState> | undefined;
  customOptions: CustomOption[] = [];
  modules: QuillModules | undefined;

  constructor(
    private stateService: StateService,
    private formService: FormService,
    private quillEditorService: QuillEditorService
  ) {}

  ngOnInit(): void {
    if (this.stateId && this.rowId) {
      const formGroup = this.formService.forms[this.stateService.states[this.stateId].get('block', 'id')] as FormGroup;
      if (formGroup && this.fieldPath.length === 0) {
        this.formGroup = formGroup.get(['rows', this.rowId]) as FormGroup;
      }
      if (formGroup && this.fieldPath.length) {
        this.formGroup = formGroup.get(['rows', this.rowId, ...this.fieldPath]) as FormGroup;
      }
      this.blockState = this.stateService.states[this.stateId];
    }

    // this.block = this.dataReadService.blocks[this.blockId]
    this.customOptions = this.quillEditorService.customOptions;
    if (this.options) {
      this.modules = this.quillEditorService.modules(this.options, 'quill-form-quill-inline');
    }
  }

  /**
   * TODO: this may have trouble when fieldPath.length !== 0
   */
  get styles(): QuillEditorStyles | undefined {
    if (this.stateId && this.blockState && typeof this.stylesFunction === 'function') {
      const form = this.formService.forms[this.stateService.states[this.stateId].get('block', 'id')];
      const blockWidth = this.blockState.get('blockWidth');
      if (form && blockWidth) {
        return this.stylesFunction(form.value, blockWidth, !!this.blockState.get('background', 'url'));
      }
    }
    return;
  }

  /**
   * template methods
   */

  onEditorCreated(): void {
    this.quillEditorService.onEditorCreated(this.quillEditorComponent);
  }
}
