import { Inject, Injectable } from '@angular/core';
import * as SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import { GLOBAL_RX_STATE, GlobalState } from './state';
import { RxState } from '@rx-angular/state';
import { usAutocompletePro } from 'smartystreets-javascript-sdk';

@Injectable({
  providedIn: 'root',
})
export class SmartystreetsService {
  paymentRequired: boolean = true;

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>
  ) {}

  usAutocompleteLookup(
    term: string,
    prefer: string[],
    stateFilter: string[],
    websiteKey: string
  ): Promise<usAutocompletePro.Lookup> {
    const Lookup = SmartyStreetsSDK.usAutocompletePro.Lookup;
    const SmartyStreetsCore = SmartyStreetsSDK.core;
    const credentials = new SmartyStreetsCore.SharedCredentials(websiteKey);
    const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);
    const client = clientBuilder.buildUsAutocompleteProClient();
    const lookup = new Lookup(term);
    lookup.search = term; // redundant
    lookup.includeOnlyStates = stateFilter;
    lookup.preferStates = prefer;
    lookup.preferGeolocation = 'none';
    lookup.maxResults = 10;
    return client.send(lookup);
  }
}
